<template>
  <div class="container">
    <header class="jumbotron">
      <h3>{{ content }}</h3>
    </header>
    <p>
      <strong>ผู้ใช้:</strong>
      {{this.user.username}}
    </p>
    <p>
      <strong>จังหวัด:</strong>
      {{this.user.province}}
    </p>
    <Form @submit="handleData" :validation-schema="schema">
          <div class="form-group">
            <label for="rawA1">คำตอบส่วนที่ 1 </label>
            <Field name="rawA1" type="text" class="form-control" v-model="rawA1"/>
            <ErrorMessage name="rawA1" class="error-feedback" />
          </div>
          <table>
            <thead>
              <tr>
                <th v-for="i in 12" :key="i">{{ i }}</th>
              </tr>
            </thead>
          </table>
          <div class="form-group">
            <label for="rawA2">คำตอบส่วนที่ 2 </label>
            <Field name="rawA2" type="text" class="form-control" />
            <ErrorMessage name="rawA2" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="rawA3">คำตอบส่วนที่ 3 </label>
            <Field name="rawA3" type="text" class="form-control" />
            <ErrorMessage name="rawA3" class="error-feedback" />
          </div>
            
          <div class="form-group">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
               ส่งคำตอบ
            </button>
          </div>
      </Form>
      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";

export default {
  name: "User",
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  data() {
    const schema = yup.object().shape({
      rawA1: yup
        .string()
        .required("กรุณากรอกข้อมูลส่วนที่ 1")
        .length(24, "ส่วนที่หนึ่งต้องมีความยาว 24 ข้อ")
        .matches('^[0-4]*$', "กรอกคำตอบ 1-4 เท่านั้น"),
      rawA2: yup
        .string()
        .required("กรุณากรอกข้อมูลส่วนที่ 2"),
      rawA3: yup
        .string()
        .required("กรุณากรอกข้อมูลส่วนที่ 3"),
    });
    return {
      successful: false,
      loading: false,
      message: "",
      content: "",
      rawA1: "",
      rawA2: "",
      rawA3: "",
      province: "",
      userId: "",
      schema,
      user: {username: "", province: ""},
    };
  },
  mounted() {
    UserService.getUserBoard().then(
      (response) => {
        this.content = response.data.message;
        this.user = response.data.user;
        this.province = response.data.user.province;
        this.userId = response.data.user._id;
        console.log(response.data)
      },
      (error) => {
        this.content =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );
  },
  methods: {
    handleData(input, { resetForm }) {
      this.message = "";
      this.successful = false;
      this.loading = true;
      input.province = this.province;
      input.userId = this.userId;
      this.$store.dispatch("user/data", input).then(
        (data) => {
          this.message = data.message;
          this.successful = true;
          this.loading = false;
          resetForm();

        },
        (error) => {
          
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(error.response);
          this.successful = false;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.error-feedback {
  color: red;
}
</style>